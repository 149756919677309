import { downloadFromDrive } from "../api/fileService";
import {
  createOne,
  deleteOne,
  getAll,
  getDocuments,
  updateOne,
  getDocument,
} from "../utils/database";
import { formatDate } from "../utils/date";

class Point {
  static collectionName = "points";

  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.image = data.image;
    this.video = data.video;
    this.duration = data.duration;
    this.description = data.description;
    this.coordinates = data.coordinates;
    this.address = data.address;
    this.idVisit = data.idVisit;
    this.order = data.order || "";
    this.creationDate = data.creationDate;
  }

  static async create({
    name,
    image,
    video,
    duration,
    description,
    coordinates,
    address,
    idVisit,
    // order
  }) {
    try {
      const data = {
        name: name,
        image,
        video,
        duration,
        description,
        coordinates,
        address,
        idVisit,
        // order,
        creationDate: formatDate(new Date()),
      };
      const response = await createOne(this.collectionName, data);
      const id = response.id;
      await updateOne(this.collectionName, id, { id });

      const fileId = getDriveFileId(video);
      if (fileId) {
        downloadFromDrive({ pointId: id, id: fileId });
      }

      return id;
    } catch (e) {
      throw e;
    }
  }

  static getOne(id) {
    return getDocument(this.collectionName, id);
  }

  static async update(id, pointData) {
    try {
      const point = await this.getOne(id);
      if (!point) throw new Error("Point not found");

      // Update point's order in the database
      await updateOne(this.collectionName, id, {
        ...pointData,
        order: pointData.order || "", // Ensure the new order is updated
      });
      console.log(`Point with ID ${id} updated with new order.`);
    } catch (error) {
      console.error("Error in Point.update:", error);
      throw error;
    }
  }

  static getAllPoints(callBack) {
    return getAll(this.collectionName, callBack);
  }

  static getAllPt(query = undefined) {
    return getDocuments(this.collectionName, query);
  }

  async save() {
    await updateOne(Point.collectionName, this.id, {
      name: this.name?.toLowerCase(),
      image: this.image,
      video: this.video,
      duration: this.duration,
      description: this.description,
      coordinates: this.coordinates,
      address: this.address,
      idVisit: this.idVisit,
      order: this.order, // Include the order property in the save
    });

    const fileId = getDriveFileId(this.video);
    if (fileId) {
      downloadFromDrive({ pointId: this.id, id: fileId });
    }
  }

  async delete() {
    await deleteOne(Point.collectionName, this.id);
  }

  // static async getPoints() {
  //     const points = [];
  //     const querySnapshot = await firestore.collection('points').get();
  //     querySnapshot.forEach((doc) => {
  //         const data = doc.data();
  //         const point = new Point({
  //             id: doc.id,
  //             name: data.name,
  //             images: data.images,
  //             video: data.video,
  //             duration: data.duration,
  //             description: data.description,
  //             categories: data.categories,
  //             coordinates: data.coordinates,
  //             address: data.address,
  //         });
  //         points.push(point);
  //     });
  //     return points;
  // }
}

const getDriveFileId = (url) => {
  // console.log("isValidUrl(url)", isValidUrl(url));

  // if (!isValidUrl(url)) return null;
  const match = url.match(/(?:\/file\/d\/|id=)([\w-]+)/);
  console.log(match);
  if (match && match?.length > 1) {
    return match[1];
  }
  return "";
};
// const isValidUrl = (url) => {
//   const urlPattern =
//     /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;

//   console.log(urlPattern.test(url));
//   return urlPattern.test(url);
// };
export default Point;
