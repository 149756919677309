import React, { useCallback, useRef, useState, useEffect } from "react";
import {
  GoogleMap,
  Marker,
  LoadScript,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import { googleMapsApiKey } from "../api/axiosConfig";

const libraries = ["places"];

const containerStyle = {
  width: "100%",
  height: "100%",
};

const MapComponent = ({
  setShowMap,
  lat,
  lng,
  setLat,
  setLng,
  setLocation,
  setSelectedRow,
  setCity,
  needLocation,
}) => {
  const searchBoxRef = useRef(null);
  const [currentLocation, setCurrentLocation] = useState(null);
  const defaultProps = {
    center: {
      lat: lat || 48.864716,
      lng: lng || 2.349014,
    },
    zoom: 14,
  };

  const extractLocationInfo = (result) => {
    if (!result || !result.address_components) {
      return {
        lat,
        lng,
        city: "",
        state: "",
        country: "",
        formattedAddress: `${lat}, ${lng}`,
      };
    }

    let locationInfo = {
      lat: result.geometry?.location?.lat() || null,
      lng: result.geometry?.location?.lng() || null,
      city: "",
      state: "",
      country: "",
      formattedAddress: result.formatted_address || "",
    };

    for (let component of result.address_components) {
      if (component.types.includes("locality")) {
        locationInfo.city = component.long_name; // ✅ Preferred city
      } else if (component.types.includes("postal_town")) {
        locationInfo.city = component.long_name; // ✅ UK cities like Glasgow
      } else if (component.types.includes("administrative_area_level_1")) {
        locationInfo.state = component.long_name; // 🏛️ State fallback
      } else if (component.types.includes("country")) {
        locationInfo.country = component.long_name;
      }
    }

    // 🛠 Remove postal codes from city names (e.g., "Glasgow G2 5EA" → "Glasgow")
    locationInfo.city = locationInfo.city.replace(/\b\d{4,5}\b.*$/, "").trim();
    locationInfo.city = locationInfo.city
      .replace(/\b[A-Z]{1,2}\d{1,2}[A-Z]?\s*\d[A-Z]{2}\b$/, "")
      .trim(); // UK-style codes

    // 🏙 If no city is found, fallback to the state (as last resort)
    if (!locationInfo.city) {
      locationInfo.city = locationInfo.state;
    }

    return locationInfo;
  };

  useEffect(() => {
    if (needLocation && lat && lng && window.google) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: { lat, lng } }, (results, status) => {
        if (status === "OK" && results[0]) {
          const locationInfo = extractLocationInfo(results[0]);
          setCurrentLocation(locationInfo);
          setLocation(locationInfo.formattedAddress);
          setCity(locationInfo.city); // Always clean city name
        }
      });
    }
  }, [lat, lng, setLocation]);

  const handlePlaceSelect = (place) => {
    if (place.geometry) {
      const newLat = place.geometry.location.lat();
      const newLng = place.geometry.location.lng();

      setLat(newLat);
      setLng(newLng);

      if (needLocation) {
        const locationInfo = extractLocationInfo(place);
        setCurrentLocation(locationInfo);
        setLocation(locationInfo.formattedAddress);
        setCity(
          locationInfo.formattedAddress
            ?.split(",")
            ?.at(-2) // Get the second-to-last part of the address
            ?.trim()
            ?.replace(/^\d+\s*/, "") // Remove leading digits and spaces
        );
      }
    }
  };

  const handlePlacesChanged = () => {
    const places = searchBoxRef.current.getPlaces();
    if (places.length > 0) {
      handlePlaceSelect(places[0]);
    }
  };

  const handleMarkerDrag = useCallback(
    (event) => {
      const newLat = event.latLng.lat();
      const newLng = event.latLng.lng();

      setLat(newLat);
      setLng(newLng);

      if (needLocation) {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode(
          {
            location: { lat: lat, lng: lng },
          },
          (results, status) => {
            if (status === "OK" && results[0]) {
              const locationInfo = extractLocationInfo(results[0]);
              locationInfo.lat = lat;
              locationInfo.lng = lng;
              setCurrentLocation(locationInfo);
              setLocation(locationInfo.formattedAddress);
              setCity(
                locationInfo.formattedAddress
                  ?.split(",")
                  ?.at(-2) // Get the second-to-last part of the address
                  ?.trim()
                  ?.replace(/\b\d{4,5}\b.*$/, "") // Removes postal codes at the end
                  ?.replace(/\b[A-Z]{1,2}\d{1,2}[A-Z]?\s*\d[A-Z]{2}\b$/, "") // UK-style postal codes (e.g., "G2 5EA")
                  ?.trim()
              );
            } else {
              const fallbackAddress = `${lat.toFixed(6)}, ${lng.toFixed(6)}`;
              const fallbackLocation = {
                lat: lat,
                lng: lng,
                city: "",
                state: "",
                country: "",
                formattedAddress: fallbackAddress,
              };
              setCurrentLocation(fallbackLocation);
              setLocation(fallbackAddress);
            }
          }
        );
      }
    },
    [setLat, setLng, setLocation]
  );

  const handleMapClick = useCallback(
    (event) => {
      const newLat = event.latLng.lat();
      const newLng = event.latLng.lng();

      setLat(newLat);
      setLng(newLng);

      if (needLocation) {
        const geocoder = new window.google.maps.Geocoder();

        geocoder.geocode(
          {
            location: { lat: newLat, lng: newLng },
          },
          (results, status) => {
            if (status === "OK" && results[0]) {
              const locationInfo = extractLocationInfo(results[0]);
              locationInfo.lat = newLat;
              locationInfo.lng = newLng;
              setCurrentLocation(locationInfo);
              setLocation(locationInfo.formattedAddress);
              setCity(
                locationInfo.formattedAddress
                  ?.split(",")
                  ?.at(-2) // Get the second-to-last part of the address
                  ?.trim()
                  ?.replace(/\b\d{4,5}\b.*$/, "") // Removes postal codes at the end
                  ?.replace(/\b[A-Z]{1,2}\d{1,2}[A-Z]?\s*\d[A-Z]{2}\b$/, "") // UK-style postal codes (e.g., "G2 5EA")
                  ?.trim()
              );
            } else {
              const fallbackAddress = `${newLat.toFixed(6)}, ${newLng.toFixed(
                6
              )}`;
              const fallbackLocation = {
                lat: newLat,
                lng: newLng,
                city: "",
                state: "",
                country: "",
                formattedAddress: fallbackAddress,
              };
              setCurrentLocation(fallbackLocation);
              setLocation(fallbackAddress);
            }
          }
        );
      }
    },
    [setLat, setLng, setLocation]
  );

  return (
    <div className="fixed top-0  z-50   left-0">
      <div className="fixed inset-0  transition-opacity">
        <div className="absolute inset-0 bg-gray-900 opacity-75" />
      </div>

      <div className="w-screen h-screen flex items-center relative">
        <div className="h-1/2 md:w-1/2 w-full m-auto p-1 bg-white">
          <LoadScript
            googleMapsApiKey={googleMapsApiKey}
            libraries={libraries}
            loadingElement={<div>Loading...</div>}
          >
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={defaultProps.center}
              zoom={14}
              onClick={handleMapClick}
            >
              {setLat !== undefined && (
                <StandaloneSearchBox
                  onLoad={(ref) => {
                    searchBoxRef.current = ref;
                  }}
                  onPlacesChanged={handlePlacesChanged}
                >
                  <input
                    type="text"
                    placeholder="Enter a location"
                    style={{
                      boxSizing: `border-box`,
                      border: `1px solid transparent`,
                      width: `240px`,
                      height: `32px`,
                      padding: `0 12px`,
                      borderRadius: `3px`,
                      boxShadow: `0 2px 6px rgba(0, 0, 0, 0.4)`,
                      fontSize: `14px`,
                      outline: `none`,
                      textOverflow: `ellipses`,
                      position: "absolute",
                      left: "50%",
                      top: "10px",
                      marginLeft: "-120px",
                    }}
                  />
                </StandaloneSearchBox>
              )}

              <Marker
                position={{
                  lat: lat,
                  lng: lng,
                }}
                draggable={true}
                onDragEnd={handleMarkerDrag}
              />
            </GoogleMap>
          </LoadScript>

          {currentLocation && (
            <div className="pt-4 pb-0 text-sm text-white">
            {currentLocation.formattedAddress}
            </div>
          )}

          <div className="flex gap-3">
            <button
              onClick={() => {
                if (currentLocation) {
                  if (needLocation) {
                    setLocation(currentLocation.formattedAddress);
                  }
                }
                setShowMap(false);
                if (setSelectedRow !== undefined) {
                  setSelectedRow(undefined);
                }
              }}
              className="mt-4 lg:w-1/4 w-full bg-amber-500 p-4 rounded-lg hover:bg-amber-600 text-white font-bold text-xl text-center cursor-pointer"
            >
              Choose
            </button>

            <button
              onClick={() => {
                setShowMap(false);
              }}
              className="mt-4 p-4 rounded-lg hover:bg-gray-600 text-white font-bold text-center cursor-pointer"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapComponent;
