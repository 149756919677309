import {
  createOne,
  getAll,
  getDocument,
  getDocuments,
  updateOne,
  deleteOne,
} from "../utils/database";

class Coupon {
  static collectionName = "coupons";

  constructor({ id, code_name, discount_value, from, to , createdAt , updatedAt}) {
    this.id = id;
    this.code_name = code_name;
    this.discount_value = discount_value;
    this.from = from;
    this.to = to;
    this.createdAt = createdAt || null;
    this.updatedAt = updatedAt || null;
  }

  static async create(couponData) {
    console.log("Coupon.create called with data:", couponData);
    try {
      const docRef = await createOne(this.collectionName, couponData);
      console.log("Coupon created with ID:", docRef.id);
      return docRef.id;
    } catch (error) {
      console.error("Error in Coupon.create:", error);
      throw error;
    }
  }

  static getAllCoupons(callBack) {
    return getAll(this.collectionName, callBack);
  }

  static getAllCpns(query = "") {
    return getDocuments(this.collectionName, query);
  }

  static getOne(id) {
    return getDocument(this.collectionName, id);
  }

  static fromJSON(id, data) {
    return new Coupon({
      id,
      code_name: data.code_name || "",
      from: data.from || "",
      to: data.to || "",
      discount_value: data.discount_value || "",
    });
  }

  async save() {
    await updateOne(Coupon.collectionName, this.id, {
      code_name: this.code_name,
      from: this.from,
      to: this.to,
      discount_value: this.discount_value,
      updatedAt: this.updatedAt, // Ensure `updatedAt` is directly set here
    });
  }

  async addView(userId) {
    if (!this.views.includes(userId)) {
      this.views.push(userId);
      await updateOne(this.constructor.collectionName, this.id, {
        views: this.views,
      });
    }
  }

  async delete() {
    try {
      await deleteOne(this.constructor.collectionName, this.id);
      console.log(`Coupon with ID ${this.id} deleted successfully.`);
    } catch (error) {
      console.error("Error in Coupon.delete:", error);
      throw error;
    }
  }
}

export default Coupon;
