import {
  createOne,
  getAll,
  getDocument,
  getDocuments,
  updateOne,
  deleteOne,
} from "../utils/database";

class Monument {
  static collectionName = "monuments";

  constructor({
    id,
    name,
    cityID,
    coordinates,
    images,
    createdAt,
    description,
    // duration,
    thumbnailVideo,
    AdditionalVideos,
    iconUrl,
    anecdotes,
    documents,
    authors,
    // SourceData,
    views,
  }) {
    this.id = id;
    this.name = name;
    this.cityID = cityID;
    this.coordinates = coordinates;
    this.createdAt = createdAt;
    this.description = description;
    this.images = images;
    // this.duration = duration;
    this.thumbnailVideo = thumbnailVideo;
    this.AdditionalVideos = AdditionalVideos;
    this.iconUrl = iconUrl;
    this.anecdotes = anecdotes;
    this.documents = documents;
    this.authors = authors;
    // this.SourceData = SourceData;
    this.views = views;
  }

  static async create(monumentData) {
    console.log("Monument.create called with data:", monumentData);
    try {
      const docRef = await createOne(this.collectionName, monumentData);
      console.log("Monument created with ID:", docRef.id);
      return docRef.id;
    } catch (error) {
      console.error("Error in Monument.create:", error);
      throw error;
    }
  }

  static getAllMonuments(callBack) {
    return getAll(this.collectionName, callBack);
  }

  static getAllMnmnts(query = "") {
    return getDocuments(this.collectionName, query);
  }

  static getOne(id) {
    return getDocument(this.collectionName, id);
  }

  static fromJSON(id, data) {
    return new Monument({
      id,
      name: data.name || "",
      cityID: data.cityID,
      coordinates: data.coordinates,
      createdAt: data.createdAt,
      description: data.description || "",
      images: data.images || [],
      // duration: data.duration || 0,
      thumbnailVideo: data.thumbnailVideo || "",
      AdditionalVideos: data.AdditionalVideos || [{ title: "", url: "" }],
      iconUrl: data.iconUrl || "",
      anecdotes: data.anecdotes || "",
      documents: data.documents,
      authors: data.authors,
      // SourceData: data.SourceData || [{ title: "", url: "" }],
      views: data.views || [],
    });
  }

  static async update(id, monumentData) {
    try {
      const monument = await this.getOne(id); // Get the existing monument
      if (!monument) {
        throw new Error("Monument not found");
      }

      // Update the monument data directly in the database
      await updateOne(this.collectionName, id, monumentData);
      console.log(`Monument with ID ${id} updated successfully.`);
    } catch (error) {
      console.error("Error in Monument.update:", error);
      throw error;
    }
  }

  async addView(userId) {
    if (!this.views.includes(userId)) {
      this.views.push(userId);
      await updateOne(this.constructor.collectionName, this.id, {
        views: this.views,
      });
    }
  }

  async delete() {
    try {
      await deleteOne(this.constructor.collectionName, this.id);
      console.log(`Monument with ID ${this.id} deleted successfully.`);
    } catch (error) {
      console.error("Error in Monument.delete:", error);
      throw error;
    }
  }
}

export default Monument;
