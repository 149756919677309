import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  RiCloseFill,
  RiAddLine,
  RiPencilLine,
  RiSubtractLine,
} from "react-icons/ri";
import { Progress } from "@material-tailwind/react";
import Switch from "react-switch";
import ReactSelect from "react-select";
import { Spinner } from "reactstrap";
import AlertContext from "../../context/AlertContext/allertContext";
import {
  customStyles,
  toogleOverflow,
} from "../../utils/helpers/style_helpers";
import Monument from "../../models/Monument";
import City from "../../models/City";
import { deleteFile, updateFile, uploadFile } from "../../api/fileService";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import MapComponent from "../Map";
import { IoMdClose } from "react-icons/io";
import { FaCloudUploadAlt, FaPlus } from "react-icons/fa";
import { values } from "lodash";

const steps = [
  "Informations générales",
  "Présentation",
  "Anecdotes et Documents",
  "Liens",
  // "Informations supplémentaires",
];

export default function Component({
  setShowFormular,
  data,
  setSelectedMonument,
  isConsulting,
}) {
  const { showAlert } = useContext(AlertContext);
  const [currentStep, setCurrentStep] = useState(0);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(undefined);
  const [thumbnailFile, setThumbnailFile] = useState(null);
  const [iconFile, setIconFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [progress, setProgress] = useState(0);
  const [coordinates, setCoordinates] = useState(
    data?.coordinates || { lat: 48.864716, lng: 2.349014 }
  );
  const [thumbnailPreview, setThumbnailPreview] = useState("");
  const [iconPreview, setIconPreview] = useState("");
  const filePondRef = useRef(null);
  const iconUrlRef = useRef(null);
  const [descriptionAttachments, setDescriptionAttachments] = useState([]);
  const [descriptionPreviewUrls, setDescriptionPreviewUrls] = useState([]);
  const descriptionInputRef = useRef(null);
  const attachmentInputRef = React.useRef(null);
  const [documentFiles, setDocumentFiles] = useState([]);
  const [documentPreviews, setDocumentPreviews] = useState([]);
  const [documentType, setDocumentType] = useState("upload");
  /*******************************************************************************************************************************************/
  // DESCRIPTION RELATED CODE
  const handleDescriptionAttachmentsChange = (event) => {
    const files = Array.from(event.target.files);

    // Create preview URLs for new files
    const newPreviewUrls = files.map((file) => URL.createObjectURL(file));

    setDescriptionPreviewUrls((prevUrls) => [...prevUrls, ...newPreviewUrls]);
    setDescriptionAttachments((prevAttachments) => [
      ...prevAttachments,
      ...files,
    ]);

    // Update formik
    const currentAttachments = validation.values.description.attachments || [];
    validation.setFieldValue("description.attachments", [
      ...currentAttachments,
      ...files.map((file) => file.name), // Store filenames temporarily
    ]);
  };

  const removeDescriptionAttachment = (index) => {
    // Revoke the URL to prevent memory leaks
    URL.revokeObjectURL(descriptionPreviewUrls[index]);

    setDescriptionPreviewUrls((prevUrls) =>
      prevUrls.filter((_, i) => i !== index)
    );

    setDescriptionAttachments((prevAttachments) =>
      prevAttachments.filter((_, i) => i !== index)
    );

    // Update formik
    const currentAttachments = validation.values.description.attachments;
    validation.setFieldValue(
      "description.attachments",
      currentAttachments.filter((_, i) => i !== index)
    );
  };

  // Clean up preview URLs when component unmounts
  useEffect(() => {
    return () => {
      descriptionPreviewUrls.forEach((url) => {
        if (url.startsWith("blob:")) {
          URL.revokeObjectURL(url);
        }
      });
    };
  }, []);
  /*******************************************************************************************************************************************/
  // VALIDATION & FIELDS
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: data?.name || "",
      description: data?.description || {
        title: "",
        content: "",
        attachments: [],
      },
      anecdotes: data?.anecdotes || [{ title: "", content: "" }],
      thumbnailImage: data?.images || "",
      thumbnailVideo: data?.thumbnailVideo || "",
      AdditionalVideos: data?.AdditionalVideos?.filter(
        (video) => video.title || video.url
      ) || [{ title: "", url: "" }],
      documents: data?.documents || [
        { title: "", content: "", attachment: null, link: "" },
      ],
      authors: data?.authors || "",
      icon: data?.iconUrl || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Le nom du monument est requis"), // required
      // description: Yup.string().required("Please Enter monument description"), // required
      // duration: Yup.number().positive().required("Please Enter visit duration"), // required
      thumbnailImage: Yup.string().required(
        "Une image du monument est requise"
      ), // required
      icon: Yup.mixed().required("Une icône du monument est requise"), // required
      thumbnailVideo: Yup.string().url("Must be a valid URL").optional(), // required
      AdditionalVideos: Yup.array().of(
        Yup.object().shape({
          title: Yup.string(),
          url: Yup.string().url("Must be a valid URL"),
        })
      ),
      // SourceData: Yup.array().of(
      //   Yup.object().shape({
      //     title: Yup.string(),
      //     url: Yup.string().url("Must be a valid URL"),
      //   })
      // ),
    }),
    onSubmit: async (values) => {
      if (loading) return;
      setLoading(true);

      try {
        if (!selectedCity) {
          showAlert("Please select a city.");
          return;
        }

        showAlert("Preparing monument data...", "info");
        setProgress(10);

        const monumentData = {
          name: values.name,
          cityID: selectedCity.value,
          coordinates: coordinates,
          description: values.description,
          documents: values.documents,
          thumbnailVideo: values.thumbnailVideo || null,
          AdditionalVideos: values.AdditionalVideos.filter(
            (video) => video.title || video.url
          ),
          images: data?.images || [],
          iconUrl: values.icon,
          anecdotes: values.anecdotes,
          authors: values.authors,
        };

        showAlert("Handling files...", "info");

        // Handle thumbnail image
        if (thumbnailFile && thumbnailFile !== data?.images?.[0]) {
          try {
            let imageUrl;
            if (data) {
              // Updating existing monument
              imageUrl = await updateFile(
                thumbnailFile,
                data.images?.[0],
                "monuments",
                (progressEvent) => {
                  const progressPercentage = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  setProgress(10 + progressPercentage * 0.4);
                }
              );
            } else {
              // Creating new monument
              imageUrl = await uploadFile(
                thumbnailFile,
                "monuments",
                (progressEvent) => {
                  const progressPercentage = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  setProgress(10 + progressPercentage * 0.4);
                }
              );
            }
            if (imageUrl) {
              monumentData.images = [imageUrl];
            }
          } catch (e) {
            showAlert(
              `Error handling image ${data ? "update" : "upload"}`,
              "error"
            );
            setLoading(false);
            return;
          }
        }

        if (
          descriptionAttachments.length > 0 ||
          (data?.description?.attachments?.length || 0) >
            descriptionAttachments.length
        ) {
          // Get the original attachment URLs
          const originalAttachments = data?.description?.attachments || [];

          // Upload new attachments and get their URLs
          const attachmentUrls = await Promise.all(
            descriptionAttachments.map(async (file, index) => {
              if (typeof file === "string") {
                // This is an existing URL, keep it
                return file;
              }

              let url;
              const existingUrl = originalAttachments[index];

              if (existingUrl) {
                // Update existing file
                url = await updateFile(
                  file,
                  existingUrl,
                  "monuments/description-attachments",
                  (progressEvent) => {
                    const progressPercentage = Math.round(
                      (progressEvent.loaded * 100) / progressEvent.total
                    );
                    setProgress(50 + progressPercentage * 0.3);
                  }
                );
              } else {
                // Upload new file
                url = await uploadFile(
                  file,
                  "monuments/description-attachments",
                  (progressEvent) => {
                    const progressPercentage = Math.round(
                      (progressEvent.loaded * 100) / progressEvent.total
                    );
                    setProgress(50 + progressPercentage * 0.3);
                  }
                );
              }
              return url;
            })
          );

          // Delete any remaining files that were removed
          const removedAttachments = originalAttachments.filter(
            (url) => !attachmentUrls.includes(url)
          );

          await Promise.all(
            removedAttachments.map(async (url) => {
              try {
                await deleteFile(url);
              } catch (error) {
                console.error("Error deleting removed attachment:", error);
              }
            })
          );

          monumentData.description = {
            ...values.description,
            attachments: attachmentUrls,
          };
        }

        // Handle icon file
        if (iconFile && iconFile !== data?.iconUrl) {
          try {
            let iconUrl;
            if (data) {
              // Updating existing monument
              iconUrl = await updateFile(
                iconFile,
                data.iconUrl,
                "markers",
                (progressEvent) => {
                  const progressPercentage = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  setProgress(60 + progressPercentage * 0.3);
                }
              );
            } else {
              // Creating new monument
              iconUrl = await uploadFile(
                iconFile,
                "markers",
                (progressEvent) => {
                  const progressPercentage = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  setProgress(60 + progressPercentage * 0.3);
                }
              );
            }
            if (iconUrl) {
              monumentData.iconUrl = iconUrl;
            }
          } catch (e) {
            showAlert(
              `Error ${data ? "updating" : "uploading"} map icon`,
              "error"
            );
            setLoading(false);
            return;
          }
        }
        if (documentFiles.length > 0) {
          try {
            const documentUrls = await Promise.all(
              validation.values.documents.map(async (doc, index) => {
                // doc.link = "null";
                // If there's no new file and we have an existing attachment, keep it
                if (!documentFiles[index] && doc.attachment) {
                  return doc;
                }
                // If there's a new file, upload it
                if (documentFiles[index]) {
                  const originalAttachment = data?.documents[index]?.attachment;
                  let documentUrl;
                  const progressCallback = (progressEvent) => {
                    const progressPercentage = Math.round(
                      (progressEvent.loaded * 100) / progressEvent.total
                    );
                    setProgress(
                      data?.documents
                        ? 10 + progressPercentage * 0.4
                        : 70 + progressPercentage * 0.2
                    );
                  };
                  if (
                    originalAttachment &&
                    originalAttachment.includes("firebasestorage")
                  ) {
                    documentUrl = await updateFile(
                      documentFiles[index],
                      originalAttachment,
                      "monuments/documents-attachments",
                      (progressEvent) => {
                        const progressPercentage = Math.round(
                          (progressEvent.loaded * 100) / progressEvent.total
                        );
                        setProgress(50 + progressPercentage * 0.3);
                      }
                    );
                  } else {
                    documentUrl = await uploadFile(
                      documentFiles[index],
                      "monuments/documents-attachments",
                      progressCallback
                    );
                  }
                  return {
                    title: doc.title || "",
                    content: doc.content || "",
                    attachment: documentUrl,
                  };
                }

                return doc;
              })
            );

            // Filter out null values and update monument data
            monumentData.documents = documentUrls.filter((doc) => doc !== null);
          } catch (error) {
            console.error("Error processing document attachments:", error);
            showAlert("Error uploading documents", "error");
          }
        }

        setProgress(85);
        showAlert(
          data ? "Updating monument..." : "Creating monument...",
          "info"
        );

        if (data) {
          await Monument.update(data.id, monumentData);
        } else {
          await Monument.create(monumentData);
        }

        setProgress(100);
        showAlert(
          data
            ? "Monument updated successfully."
            : "Monument created successfully.",
          "success"
        );
        setShowFormular(false);
        setSelectedMonument(undefined);
      } catch (error) {
        console.error("Error in form submission:", error);
        showAlert("An error occurred. Please try again.");
      } finally {
        setLoading(false);
      }
    },
  });
  /*******************************************************************************************************************************************/
  // FETCH DATA FROM FIREBASE , INCLUDING THE IMAGES
  useEffect(() => {
    loadCities();
    if (data) {
      setSelectedCity(cities.find((city) => city.value === data.cityID));
      validation.setFieldValue("description", data.description || "");
      validation.setFieldValue("documents", data.documents || "");
      validation.setFieldValue("authors", data.authors || "");
      if (data.documents[0]?.link) {
        setDocumentType("link");
      }
      if (data.images && data.images.length > 0) {
        fetchImageFromFirebase(data.images[0], "image");
        setThumbnailFile(data.images[0]);
      }
      if (data.iconUrl) {
        fetchImageFromFirebase(data.iconUrl, "icon");
        setIconFile(data.iconUrl);
      }
      if (data.description?.attachments) {
        data.description.attachments.forEach((url) => {
          fetchImageFromFirebase(url, "description");
        });
        setDescriptionAttachments(data.description.attachments);
      }
      if (data?.documents) {
        // Load each document
        data.documents.forEach(async (doc, index) => {
          if (doc.attachment && !doc.link) {
            try {
              const url = await fetchImageFromFirebase(
                doc.attachment,
                "document"
              );
              setDocumentPreviews((prev) => {
                const newPreviews = [...prev];
                newPreviews[index] = {
                  type: doc.attachment
                    ?.toLowerCase()
                    ?.match(/\.(jpg|jpeg|png|gif)$/)
                    ? "image"
                    : "file",
                  url: url,
                  name: doc.title,
                };
                return newPreviews;
              });
            } catch (error) {
              console.error("Error loading document:", error);
            }
          } else if (doc.link) {
            setDocumentPreviews((prev) => {
              const newPreviews = [...prev];
              newPreviews[index] = {
                type: doc.attachment
                  ?.toLowerCase()
                  ?.match(/\.(jpg|jpeg|png|gif)$/)
                  ? "image"
                  : "file",
                url: doc.attachment,
                name: doc.attachment,
              };
              return newPreviews;
            });
          }
        });
      }
    }
    toogleOverflow();
    return () => toogleOverflow();
  }, [data]);

  useEffect(() => {
    if (cities.length > 0 && data?.cityID) {
      const foundCity = cities.find((city) => city.value === data?.cityID);
      setSelectedCity(foundCity);
    }
  }, [cities, data?.cityID]);

  const loadCities = async () => {
    const querySnapshot = await City.getAllC();
    const cityData = querySnapshot.docs.map((doc) => ({
      value: doc.id,
      label: doc.data().name,
    }));
    setCities(cityData);

    if (data?.cityID) {
      const foundCity = cityData.find((city) => city.value === data?.cityID);
      setSelectedCity(foundCity);
    }
  };

  // Modify the fetchImageFromFirebase function to handle documents
  const fetchImageFromFirebase = async (imageUrl, type) => {
    try {
      const storage = getStorage();
      const imageRef = ref(storage, imageUrl);
      const url = await getDownloadURL(imageRef);

      switch (type) {
        case "icon":
          validation.setFieldValue("icon", url);
          setIconPreview(url);
          break;
        case "image":
          validation.setFieldValue("thumbnailImage", url);
          setThumbnailPreview(url);
          break;
        case "description":
          setDescriptionPreviewUrls((prevUrls) => [...prevUrls, url]);
          break;
        case "document":
          // Handle document previews
          return url; // Return the URL for document handling
      }
    } catch (error) {
      console.error("Error fetching image from Firebase:", error);
      showAlert(`Error loading image: ${error.message}`, "error");
    }
  };

  /*******************************************************************************************************************************************/
  // DOCUMENTS RELATED CODE

  // Handle document file changes
  const handleDocumentChange = (event, index) => {
    const file = event.target.files[0];
    if (!file) return;

    // Create a temporary preview URL
    const previewUrl = URL.createObjectURL(file);

    // Update document previews
    const newPreviews = [...documentPreviews];
    newPreviews[index] = {
      type: file.type.startsWith("image/") ? "image" : "file",
      url: previewUrl,
      name: file.name,
    };
    setDocumentPreviews(newPreviews);

    // Update the files array
    const newFiles = [...documentFiles];
    newFiles[index] = file;
    setDocumentFiles(newFiles);

    // Update formik values
    const newDocuments = [...validation.values.documents];
    newDocuments[index] = {
      ...newDocuments[index],
      attachment: null, // We'll handle the file separately
      title: newDocuments[index].title || file.name,
      content: newDocuments[index].content || file.name,
    };
    validation.setFieldValue("documents", newDocuments);
  };

  // Handle removing documents
  const handleRemoveDocument = (index) => {
    // Revoke any existing preview URL
    if (documentPreviews[index]?.url) {
      URL.revokeObjectURL(documentPreviews[index].url);
    }

    // Remove file and preview
    const newFiles = [...documentFiles];
    newFiles.splice(index, 1);
    setDocumentFiles(newFiles);

    const newPreviews = [...documentPreviews];
    newPreviews.splice(index, 1);
    setDocumentPreviews(newPreviews);

    // Update formik values
    const newDocuments = [...validation.values.documents];
    newDocuments.splice(index, 1);
    // if (newDocuments.length < 1) {
    //   newDocuments.push({ title: "", content: "", attachment: null });
    // }
    validation.setFieldValue("documents", newDocuments);
  };

  useEffect(() => {
    return () => {
      documentPreviews.forEach((preview) => {
        if (preview?.type === "image") {
          URL.revokeObjectURL(preview.url);
        }
      });
    };
  }, []);

  const renderDocumentSection = () => (
    <div className="mb-5 space-y-6">
      <div>
        <label className="flex justify-between my-4">
          <span className="text-lg font-semibold text-gray-800">Documents</span>
          <div className="flex items-center justify-end gap-3 mr-12">
            <label
              className={`${
                documentType === "upload"
                  ? "text-[#428dff] font-semibold"
                  : "text-gray-400"
              }`}
            >
              Télécharger
            </label>
            <Switch
              onChange={() =>
                setDocumentType((prev) =>
                  prev === "upload" ? "link" : "upload"
                )
              }
              checked={documentType === "link"}
              offColor="#428dff"
              onColor="#f97316"
              uncheckedIcon={false}
              checkedIcon={false}
            />
            <label
              className={`${
                documentType === "link"
                  ? "text-[#f97316] font-semibold"
                  : "text-gray-400"
              }`}
            >
              Lien
            </label>
          </div>
        </label>
      </div>

      {(validation.values.documents || []).map((document, index) => (
        <div key={index} className="relative flex flex-col gap-4 mb-2">
          <div className="flex items-start gap-2">
            {documentType === "upload" && (
              <div className="flex-grow">
                <input
                  name={`documents.${index}.title`}
                  className="w-full border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4 rounded-xl mb-2"
                  placeholder="titre du document"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={document.title}
                />
                <textarea
                  name={`documents.${index}.content`}
                  className="w-full border text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4 rounded-md"
                  placeholder="Contenu du document"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={document.content}
                />

                {!documentPreviews[index] && (
                  <div className="relative mt-4">
                    <input
                      type="file"
                      onChange={(e) => handleDocumentChange(e, index)}
                      className="hidden"
                      id={`document-${index}`}
                      key={document.attachment || "empty"} // Reset the input when cleared
                    />
                    <label
                      htmlFor={`document-${index}`}
                      className="w-full flex items-center justify-center px-4 py-2 border-2 border-dashed border-gray-300 rounded-lg cursor-pointer hover:border-gray-400"
                    >
                      <FaCloudUploadAlt className="mr-2" />
                      <span className="text-sm text-gray-500">
                        Choisir un fichier
                      </span>
                    </label>
                  </div>
                )}
                {documentPreviews[index] && (
                  <div className="mt-2 relative">
                    <img
                      src={documentPreviews[index].url}
                      alt={`Preview ${index}`}
                      className="w-full h-32 object-cover rounded-lg"
                    />
                    <button
                      type="button"
                      onClick={() => {
                        // Clear only the preview and file, keep the title
                        URL.revokeObjectURL(documentPreviews[index].url);
                        const newPreviews = [...documentPreviews];
                        newPreviews[index] = null;
                        setDocumentPreviews(newPreviews);

                        const newFiles = [...documentFiles];
                        newFiles[index] = null;
                        setDocumentFiles(newFiles);

                        // Update formik - preserve title but clear attachment
                        const newDocuments = [...validation.values.documents];
                        if (newDocuments[index]) {
                          newDocuments[index] = {
                            ...newDocuments[index],
                            attachment: null,
                          };
                        }
                        validation.setFieldValue("documents", newDocuments);
                      }}
                      className="absolute top-2 right-2 p-1 bg-red-500 text-white rounded-xl hover:bg-red-600"
                    >
                      <IoMdClose />
                    </button>
                  </div>
                )}
              </div>
            )}

            {documentType === "link" && (
              <input
                name={`documents.${index}.link`}
                className="w-full border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4 rounded-xl mb-2"
                placeholder="Lien du document"
                type="text"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={document.link}
              />
            )}

            <button
              type="button"
              onClick={() => handleRemoveDocument(index)}
              className="p-2 bg-red-100 text-red-600 rounded-xl hover:bg-red-200"
            >
              <RiSubtractLine size={20} />
            </button>
          </div>
        </div>
      ))}
      <div className="flex justify-end w-full">
        {" "}
        <button
          type="button"
          onClick={() => {
            setDocumentFiles([...documentFiles, null]);
            setDocumentPreviews([...documentPreviews, null]);
            validation.setFieldValue("documents", [
              ...validation.values.documents,
              { title: "", content: "", link: "", attachment: null },
            ]);
          }}
          className="mt-2 flex items-center gap-2 p-2 rounded-xl bg-green-100 text-green-700 hover:bg-green-300"
        >
          <RiAddLine className="inline" size={20} />
        </button>
      </div>
    </div>
  );

  /*******************************************************************************************************************************************/
  // ANECDOTES RELATED CODE :

  // Add function to handle adding new anecdotes
  const handleAddAnecdote = () => {
    validation.setFieldValue("anecdotes", [
      ...validation.values.anecdotes,
      { title: "", content: "" },
    ]);
  };

  // Add function to handle removing anecdotes
  const handleRemoveAnecdote = (index) => {
    const newAnecdotes = [...validation.values.anecdotes];
    newAnecdotes.splice(index, 1);
    // Keep at least one anecdote
    // if (newAnecdotes.length === 0) {
    //   newAnecdotes.push({ title: "", content: "" });
    // }
    validation.setFieldValue("anecdotes", newAnecdotes);
  };

  /*******************************************************************************************************************************************/
  // RENDER FORM INPUTS
  const renderStep = () => {
    if (isConsulting) {
      return (
        <>
          <div className="space-y-6">
            <div>
              <div className="pt-6">
                <h3 className="text-lg font-semibold mb-4 text-gray-900 border-b pb-2">
                  Informations générales
                </h3>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {/* Name & City */}
                  <div className="space-y-4">
                    <div>
                      <label className="text-sm font-medium text-gray-500">
                        Nom
                      </label>
                      <p className="mt-1 text-base font-medium text-gray-900">
                        {data?.name || "N/A"}
                      </p>
                    </div>
                    <div>
                      <label className="text-sm font-medium text-gray-500">
                        Ville
                      </label>
                      <p className="mt-1 text-base font-medium text-gray-900">
                        {selectedCity?.label || "N/A"}
                      </p>
                    </div>
                  </div>

                  {/* Coordinates */}
                  <div>
                    <label className="text-sm font-medium text-gray-500">
                      Coordonnées
                    </label>
                    <div className="mt-1 space-y-1">
                      <p className="text-base text-gray-900">
                        <span className="font-medium">Latitude:</span>{" "}
                        {data?.coordinates?.lat.toFixed(6)}
                      </p>
                      <p className="text-base text-gray-900">
                        <span className="font-medium">Longitude:</span>{" "}
                        {data?.coordinates?.lng.toFixed(6)}
                      </p>
                    </div>
                  </div>
                </div>

                {/* Thumbnail Image */}
                <div className="mt-6">
                  <label className="text-sm font-medium text-gray-500">
                    Image
                  </label>
                  {thumbnailPreview ? (
                    <div className="mt-2 relative rounded-lg overflow-hidden">
                      <img
                        src={thumbnailPreview}
                        alt="Monument"
                        className="w-full h-64 object-cover"
                      />
                    </div>
                  ) : (
                    <p className="mt-1 text-sm text-gray-500">
                      Aucune image disponible
                    </p>
                  )}
                </div>

                {/* Description */}
                <div className="mt-6">
                  <label className="text-gray-800 block mb-2 font-bold">
                    Présentation
                  </label>
                  {data?.description?.title || data?.description?.content ? (
                    <div className="mt-2 space-y-4">
                      <div>
                        <h4 className="text-base font-medium text-gray-900">
                          {data?.description?.title || "--"}
                        </h4>
                        <p className="mt-2 text-gray-700 whitespace-pre-wrap">
                          {data?.description?.content || "--"}
                        </p>
                      </div>

                      {data?.description?.attachments?.length > 0 && (
                        <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-4">
                          {data.description.attachments.map((url, index) => (
                            <div
                              key={index}
                              className="relative aspect-square rounded-lg overflow-hidden"
                            >
                              <img
                                src={url}
                                alt={`Attachment ${index + 1}`}
                                className="w-full h-full object-cover"
                              />
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ) : (
                    <p className="mt-1 text-sm text-gray-500">
                      Aucune description disponible
                    </p>
                  )}
                </div>

                {/* Anecdote */}
                <div className="mt-6">
                  <label className="text-sm font-medium text-gray-500">
                    Anecdotes
                  </label>
                  {data?.anecdotes?.length > 0 &&
                  data?.anecdotes[0].content &&
                  data?.anecdotes[0].title ? (
                    <div className="mt-2 space-y-4">
                      {data.anecdotes.map((anecdote, index) => (
                        <div
                          key={index}
                          className="border-l-2 border-orange-500 pl-4"
                        >
                          <h4 className="font-medium text-gray-900">
                            {anecdote.title}
                          </h4>
                          <p className="mt-1 text-gray-700">
                            {anecdote.content}
                          </p>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p className="mt-1 text-sm text-gray-500">
                      Aucune anecdote disponible
                    </p>
                  )}
                </div>

                {/* Authors */}
                {data?.authors && (
                  <div className="mt-6">
                    <label className="text-sm font-medium text-gray-500">
                      Auteurs
                    </label>
                    <p style={{ whiteSpace: "pre-line" }}>{data?.authors}</p>
                  </div>
                )}
              </div>
            </div>

            {/* Additional Information Section */}
            <div>
              <div className="pt-6">
                <h3 className="text-lg font-semibold mb-4 text-gray-900 border-b pb-2">
                  Informations supplémentaires
                </h3>

                {/* Videos */}
                <div className="space-y-6">
                  <div>
                    <label className="text-sm font-medium text-gray-500">
                      Lien video
                    </label>
                    {data?.thumbnailVideo ? (
                      <a
                        href={data.thumbnailVideo}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="mt-1 block text-blue-600 hover:text-blue-800"
                      >
                        {data.thumbnailVideo}
                      </a>
                    ) : (
                      <p className="mt-1 text-sm text-gray-500">
                        Aucun lien disponible
                      </p>
                    )}
                  </div>

                  {/* Additional Videos */}
                  <div>
                    <label className="text-sm font-medium text-gray-500">
                      Videos supplémentaires
                    </label>
                    <div className="mt-2 space-y-3">
                      {data?.AdditionalVideos?.length > 0 ? (
                        data.AdditionalVideos.map((video, index) => (
                          <div key={index} className="flex flex-col">
                            <span className="font-medium text-gray-900">
                              {video.title}
                            </span>
                            <a
                              href={video.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-600 hover:text-blue-800"
                            >
                              {video.url}
                            </a>
                          </div>
                        ))
                      ) : (
                        <p className="text-sm text-gray-500">
                          Aucune vidéo supplémentaire
                        </p>
                      )}
                    </div>
                  </div>

                  {/* Documents */}
                  <div>
                    <label className="text-sm font-medium text-gray-500">
                      Documents
                    </label>
                    <div className="mt-2">
                      {data?.documents?.length > 0 ? (
                        data.documents.map((doc, index) => (
                          <div className="flex items-start gap-2">
                            <div className="flex-grow">
                              <div className="w-full text-gray-500 py-2 pl-4">
                                {doc.title}
                              </div>
                              {doc.attachment && (
                                <img
                                  src={doc.attachment}
                                  alt={`Preview ${index}`}
                                  className="w-full h-32 object-cover rounded-lg"
                                />
                              )}
                            </div>
                          </div>
                        ))
                      ) : (
                        <p className="text-sm text-gray-500">
                          Aucun document disponible
                        </p>
                      )}
                    </div>
                  </div>

                  {/* Map Icon */}
                  <div>
                    <label className="text-sm font-medium text-gray-500">
                      Icone
                    </label>
                    {iconPreview ? (
                      <div className="mt-2">
                        <img
                          src={iconPreview}
                          alt="Map Icon"
                          className="w-16 h-16 object-contain"
                        />
                      </div>
                    ) : (
                      <p className="mt-1 text-sm text-gray-500">
                        Aucune icône disponible
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      switch (currentStep) {
        case 0:
          return (
            <>
              <div className="flex gap-4 w-full mt-4">
                <div className="mb-3 w-1/2">
                  <label
                    htmlFor="name"
                    className="text-gray-800 block mb-2 font-bold"
                  >
                    Nom <span className="text-red-500">*</span>
                  </label>
                  <input
                    name="name"
                    className="w-full border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4 rounded-xl"
                    placeholder="Entrer le nom"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.name || ""}
                  />
                  {validation.touched.name && validation.errors.name ? (
                    <p className="text-red-500 text-sm mt-2.5">
                      {validation.errors.name}
                    </p>
                  ) : null}
                </div>
                <div className="mb-3 w-1/2">
                  <label className="block font-bold mb-3" htmlFor="city">
                    Ville <span className="text-red-500">*</span>
                  </label>
                  <ReactSelect
                    value={selectedCity}
                    isMulti={false}
                    options={cities}
                    onChange={(val) => setSelectedCity(val)}
                    menuShouldScrollIntoView={true}
                    maxMenuHeight={200}
                    className="!rounded-xl"
                    styles={customStyles}
                  />
                </div>
              </div>

              <div className="my-3">
                <label
                  htmlFor="thumbnail"
                  className="text-gray-800 block mb-2 font-bold"
                >
                  Image <span className="text-red-500">*</span>
                </label>

                <div className="relative  ">
                  {(data?.images?.length > 0 || thumbnailPreview) && (
                    <label
                      htmlFor="thumbnail"
                      className="cursor-pointer absolute bg-orange-500 rounded-xl p-1.5 -right-2 -top-2 "
                    >
                      <RiPencilLine className="text-white" size={20} />
                    </label>
                  )}
                  <input
                    ref={filePondRef}
                    type="file"
                    name="thumbnail"
                    id="thumbnail"
                    accept="image/*"
                    onChange={(e) => handleThumbnailChange(e)}
                    className={`hidden`}
                  />

                  <label
                    htmlFor={`thumbnail`}
                    className={`w-full flex items-center justify-center px-4 py-2 border-2 border-dashed border-gray-300 rounded-lg cursor-pointer hover:border-gray-400 ${
                      data?.images?.length > 0 || thumbnailPreview
                        ? "hidden"
                        : "inline"
                    } ${
                      validation.touched.thumbnailImage &&
                      validation.errors.thumbnailImage
                        ? "border-red-600 text-red-600"
                        : "border-gray-300 text-gray-500"
                    }`}
                  >
                    <FaCloudUploadAlt className="mr-2" />
                    <span className="text-sm">Choisir une image</span>
                  </label>

                  {thumbnailPreview && (
                    <img
                      src={thumbnailPreview}
                      alt="Thumbnail Preview"
                      className=" w-full h-32 object-cover"
                    />
                  )}
                </div>
                {validation.touched.thumbnailImage &&
                validation.errors.thumbnailImage ? (
                  <p className="text-red-500 text-sm mt-3">
                    {validation.errors.thumbnailImage}
                  </p>
                ) : null}
              </div>
              <div className="my-3">
                <label
                  htmlFor="iconUrl"
                  className="text-gray-800 block mb-2 font-bold"
                >
                  Icone <span className="text-red-500">*</span>
                </label>
                <div className="relative">
                  {(data?.iconUrl || iconPreview) && (
                    <label
                      htmlFor="icon"
                      className="cursor-pointer absolute bg-orange-500 rounded-xl p-1.5 -right-2 -top-2 "
                    >
                      <RiPencilLine className="text-white" size={20} />
                    </label>
                  )}

                  <input
                    ref={iconUrlRef}
                    type="file"
                    name="icon"
                    id="icon"
                    accept="image/*"
                    onChange={(e) => handleIconChange(e)}
                    className={`hidden`}
                  />

                  <label
                    htmlFor={`icon`}
                    className={`w-full flex items-center justify-center px-4 py-2 border-2 border-dashed  rounded-lg cursor-pointer hover:border-gray-400 ${
                      data?.iconUrl || iconPreview ? "hidden" : "inline"
                    } ${
                      validation.touched.icon && validation.errors.icon
                        ? "border-red-600 text-red-600"
                        : "border-gray-300 text-gray-500"
                    }`}
                  >
                    <FaCloudUploadAlt className={`mr-2`} />
                    <span className="text-sm">Choisir une icône</span>
                  </label>

                  {iconPreview && (
                    <img
                      src={iconPreview}
                      className="mt-2 w-full h-32 object-contain"
                    />
                  )}
                </div>
                {validation.touched.icon && validation.errors.icon ? (
                  <p className="text-red-500 text-sm mt-3">
                    {validation.errors.icon}
                  </p>
                ) : null}
                {/* {validation.values.icon && (
                  <img
                    src={validation.values.icon}
                    alt="Map Icon Preview"
                    className="mt-2 w-16 h-16 object-cover"
                  />
                )} */}
              </div>

              {/* <div className="mb-3">
                <label
                  htmlFor="duration"
                  className="text-gray-800 block mb-2 font-bold"
                >
                  Durée <span className="font-bold text-orange-500">(s)</span>{" "}
                  <span className="text-red-500">*</span>
                </label>
                <input
                  name="duration"
                  className="w-full border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4 rounded-xl"
                  placeholder="Entrer la durée"
                  type="number"
                  min={0}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.duration}
                />
                {validation.touched.duration && validation.errors.duration ? (
                  <p className="text-red-500 text-xs">
                    {validation.errors.duration}
                  </p>
                ) : null}
              </div> */}

              <div className="my-3">
                <label className="text-gray-800 block mb-2 font-bold">
                  Coordonnée Géographique
                </label>
                <div className="flex flex-col md:flex-row gap-4">
                  <input
                    className="w-full md:w-1/2 border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4 rounded-xl"
                    placeholder="Latitude"
                    type="number"
                    value={coordinates.lat}
                    onChange={(e) =>
                      setCoordinates({
                        ...coordinates,
                        lat: parseFloat(e.target.value),
                      })
                    }
                  />
                  <input
                    className="w-full md:w-1/2 border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4 rounded-xl"
                    placeholder="Longitude"
                    type="number"
                    value={coordinates.lng}
                    onChange={(e) =>
                      setCoordinates({
                        ...coordinates,
                        lng: parseFloat(e.target.value),
                      })
                    }
                  />
                </div>
                <h1
                  className="mt-3 bg-gray-100 p-2 text-center hover:bg-gray-200 rounded-xl w-full cursor-pointer"
                  onClick={() => setShowMap(true)}
                >
                  selectionner la position
                </h1>
              </div>
            </>
          );
        case 1:
          return (
            <div className="mb-3">
              <label
                htmlFor="description"
                className="text-gray-800 block mb-2 font-bold"
              >
                Présentation
              </label>
              <div>
                <label className="text-gray-800 block mb-2">Titre</label>
                <input
                  name="description.title"
                  className="w-full border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4 rounded-xl mb-3"
                  placeholder="Titre de la présentation"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.description.title}
                />
              </div>{" "}
              <label className="text-gray-800 block mb-2">Contenu</label>
              <textarea
                name="description.content"
                className="w-full border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4 rounded-md"
                placeholder="Contenu de la présenation"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.description.content || ""}
              />
              <div className="">
                <label className="text-gray-800 block mb-2">Images</label>
                <input
                  name="description.attachment"
                  id="description.attachment"
                  ref={descriptionInputRef}
                  type="file"
                  multiple
                  accept="image/*"
                  onChange={handleDescriptionAttachmentsChange}
                  className="hidden w-full border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4 rounded-xl"
                />
                <label
                  htmlFor={`description.attachment`}
                  className="w-full flex items-center justify-center px-4 py-2 border-2 border-dashed border-gray-300 rounded-lg cursor-pointer hover:border-gray-400"
                >
                  <FaCloudUploadAlt className="mr-2" />
                  <span className="text-sm text-gray-500">
                    Choisir un ou plusieurs fichiers
                  </span>
                </label>

                {descriptionPreviewUrls.length > 0 && (
                  <div className="grid grid-cols-3 gap-4 mt-4">
                    {descriptionPreviewUrls.map((url, index) => (
                      <div
                        key={index}
                        className="group relative aspect-square rounded-lg overflow-hidden shadow-md"
                      >
                        <img
                          src={url}
                          alt={`Attachment ${index + 1}`}
                          className="w-full h-full object-cover"
                        />
                        <button
                          type="button"
                          onClick={() => removeDescriptionAttachment(index)}
                          className="absolute top-2 right-2 p-1 rounded-xl bg-red-500 text-white opacity-0 group-hover:opacity-100 transition-opacity"
                        >
                          <IoMdClose className="w-4 h-4" />
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          );
        case 2:
          return (
            <>
              <div className="mb-3">
                <label className="text-gray-800 block mb-2 font-bold">
                  Anecdotes
                </label>
                {validation.values.anecdotes.map((anecdote, index) => (
                  <div
                    key={index}
                    className="relative flex flex-col gap-4 mb-4"
                  >
                    <div className="flex items-start gap-2">
                      <div className="flex-grow">
                        <input
                          name={`anecdotes.${index}.title`}
                          className="w-full border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4 rounded-xl mb-2"
                          placeholder="Titre de l'anecdote"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={anecdote.title}
                        />
                        <textarea
                          name={`anecdotes.${index}.content`}
                          className="w-full border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4 rounded-md"
                          placeholder="Contenu de l'anecdote"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={anecdote.content}
                        />
                      </div>

                      <button
                        type="button"
                        onClick={() => handleRemoveAnecdote(index)}
                        className="p-2 bg-red-100 text-red-600 rounded-xl hover:bg-red-200"
                      >
                        <RiSubtractLine size={20} />
                      </button>
                    </div>
                    {index < validation.values.anecdotes.length - 1 && (
                      <div className="relative my-2">
                        <div
                          className="absolute inset-0 flex items-center px-4"
                          aria-hidden="true"
                        >
                          <div className="w-full border-t border-gray-300 border-dashed" />
                        </div>
                      </div>
                    )}
                  </div>
                ))}
                <div className="flex w-full justify-end">
                  <button
                    type="button"
                    onClick={handleAddAnecdote}
                    className="mt-2 flex items-center gap-2 p-2 rounded-xl bg-green-100 text-green-700 hover:bg-green-300"
                  >
                    <RiAddLine className="inline" size={20} />
                  </button>
                </div>
              </div>

              {renderDocumentSection()}

              <div className="mb-3">
                <label className="text-gray-800 block mb-2 font-bold">
                  Auteurs
                </label>
                <textarea
                  name={`authors`}
                  className="w-full border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4 rounded-md"
                  placeholder="Auteurs"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.authors}
                />
              </div>
            </>
          );
        case 3:
          return (
            <>
              <div className="mb-3">
                <label
                  htmlFor="thumbnailVideo"
                  className="text-gray-800 block mb-2 font-bold"
                >
                  Lien video
                </label>
                <input
                  name="thumbnailVideo"
                  className="w-full border-2 text-gray-500 text-sm outline-none border-gray-200 bg-gray-50 py-2 pl-4 rounded-xl"
                  placeholder="URL de la video"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.thumbnailVideo || ""}
                />
                {/* {validation.touched.thumbnailVideo &&
                validation.errors.thumbnailVideo ? (
                  <p className="text-red-500 text-xs">
                    {validation.errors.thumbnailVideo}
                  </p>
                ) : null} */}
              </div>

              <div className="mb-3 w-full">
                <label
                  htmlFor="AdditionalVideos"
                  className="text-gray-800 block mb-2 font-bold"
                >
                  Videos supplémentaires
                </label>
                {validation.values.AdditionalVideos.map((video, index) => (
                  <div
                    key={index}
                    className="relative flex flex-col gap-4 mb-2"
                  >
                    <div className="flex items-start gap-2">
                      <div className="flex-grow">
                        <textarea
                          name={`AdditionalVideos.${index}.title`}
                          className="w-full border text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4 rounded-md"
                          placeholder="Titre de la video"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={video.title}
                        />
                        <input
                          name={`AdditionalVideos.${index}.url`}
                          className="w-full mt-4 border-2 text-sm text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4 rounded-xl"
                          placeholder="URL de la video"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={video.url}
                        />
                      </div>
                      {validation.values.AdditionalVideos.length > 1 && (
                        <button
                          type="button"
                          onClick={() => handleRemoveVideo(index)}
                          className="p-2 bg-red-100 text-red-600 rounded-xl hover:bg-red-200"
                        >
                          <RiSubtractLine size={20} />
                        </button>
                      )}
                    </div>
                    {index < validation.values.AdditionalVideos.length - 1 && (
                      <div className="relative my-2">
                        <div
                          className="absolute inset-0 flex items-center px-4"
                          aria-hidden="true"
                        >
                          <div className="w-full border-t border-gray-300 border-dashed" />
                        </div>
                        <div className="relative flex justify-center">
                          <div className="bg-white px-2">
                            <div className="h-1 w-8 rounded-xl bg-gray-100" />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
                <div className="flex w-full justify-end">
                  <button
                    type="button"
                    onClick={() =>
                      validation.setFieldValue("AdditionalVideos", [
                        ...validation.values.AdditionalVideos,
                        { title: "", url: "" },
                      ])
                    }
                    disabled={!canAddNewVideo()}
                    className={`mt-2   flex items-center gap-2 p-2  rounded-xl  ${
                      canAddNewVideo()
                        ? "bg-green-100 text-green-700 hover:bg-green-300"
                        : "bg-gray-100 text-gray-400 cursor-not-allowed"
                    }`}
                  >
                    <RiAddLine className="inline" size={20} />
                  </button>
                </div>
              </div>

              {/* <div className="mb-3">
                <label
                  htmlFor="SourceData"
                  className="text-gray-800 block mb-2 font-bold"
                >
                  Sources
                </label>
                {validation.values.SourceData.map((source, index) => (
                  <div key={index}>
                    <div className="flex flex-col gap-4 my-2">
                      <textarea
                        onChange={validation.handleChange}
                        name={`SourceData.${index}.title`}
                        placeholder="Titre"
                        onBlur={validation.handleBlur}
                        value={source.title}
                        className="w-full border text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4 rounded-md"
                      />
                      <input
                        name={`SourceData.${index}.url`}
                        className="w-full border text-sm text-gray-500 outline-none border-gray200 bg-gray-50 py-2 pl-4 rounded-xl"
                        placeholder="Source URL"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={source.url}
                      />
                    </div>
                    {index < validation.values.SourceData.length - 1 && (
                      <div className="relative my-4 ">
                        <div
                          className="absolute inset-0 flex items-center px-4"
                          aria-hidden="true"
                        >
                          <div className="w-full border-t border-gray-300 border-dashed" />
                        </div>
                        <div className="relative flex justify-center">
                          <div className="bg-white px-2">
                            <div className="h-1 w-8 rounded-xl bg-gray-100" />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
                <div className="w-full flex justify-end">
                  <button
                    type="button"
                    onClick={() =>
                      validation.setFieldValue("SourceData", [
                        ...validation.values.SourceData,
                        { title: "", url: "" },
                      ])
                    }
                    className="mt-2 px-4 py-2 bg-gray-200 text-gray-700 rounded-xl hover:bg-gray-300"
                  >
                    <RiAddLine className="inline mr-2" /> Ajouter une Source
                  </button>
                </div>
              </div> */}
            </>
          );

        default:
          return null;
      }
    }
  };
  /*******************************************************************************************************************************************/
  // SOURCE VIDEOS INPUTS SETS CONFIG
  const canAddNewVideo = () => {
    const videos = validation.values.AdditionalVideos;
    // Check if the last video entry has both title and url filled
    const lastVideo = videos[videos.length - 1];
    return lastVideo?.title?.trim() !== "" && lastVideo?.url.trim() !== "";
  };

  const handleRemoveVideo = (index) => {
    const newVideos = [...validation.values.AdditionalVideos];
    newVideos.splice(index, 1);
    // If there are no videos left, add an empty one
    if (newVideos.length === 0) {
      newVideos.push({ title: "", url: "" });
    }
    validation.setFieldValue("AdditionalVideos", newVideos);
  };

  /**********************************************************/
  // HANDLE THUMBNAILS UPLOAD

  const handleThumbnailChange = (event) => {
    if (event.target.files.length > 0) {
      const selectedFile = event.target.files[0];
      setThumbnailFile(selectedFile);

      // Create a local URL for the image file
      const previewUrl = URL.createObjectURL(selectedFile);
      setThumbnailPreview(previewUrl);

      // Assuming validation is a formik or similar object
      validation.setFieldValue("thumbnailImage", selectedFile.name);
    } else {
      setThumbnailFile(null);
      setThumbnailPreview(null); // Clear the preview if no file is selected
      validation.setFieldValue("thumbnailImage", "");
    }
  };

  const handleIconChange = (event) => {
    if (event.target.files.length > 0) {
      setIconFile(event.target.files[0]);

      // Create a local URL for the image file
      const previewUrl = URL.createObjectURL(event.target.files[0]);
      setIconPreview(previewUrl);

      validation.setFieldValue("icon", event.target.files[0].name);
    } else {
      setIconFile(null);
      validation.setFieldValue("icon", "");
    }
  };
  /**********************************************************/
  // HANDLE STEPS NAVIGATIONS
  const handleNext = () => {
    setCurrentStep((prev) => Math.min(prev + 1));
    setProgress((prev) => Math.min(prev + 50, 100)); // Assuming 2 steps, adjust as needed
  };

  const handlePrevious = () => {
    setCurrentStep((prev) => Math.max(prev - 1, 0));
    setProgress((prev) => Math.max(prev - 50, 0)); // Assuming 2 steps, adjust as needed
  };
  /********************************************************* */

  const handleMapClick = (data) => {
    setCoordinates({
      lng: data.latLng.lng(),
      lat: data.latLng.lat(),
    });
  };
  return (
    <form onSubmit={validation.handleSubmit}>
      {showMap && (
        <MapComponent
          lat={coordinates.lat}
          lng={coordinates.lng} // Make sure it's lng here
          setLat={(newLat) =>
            setCoordinates((prev) => ({ ...prev, lat: newLat }))
          }
          setLng={(newLng) =>
            setCoordinates((prev) => ({ ...prev, lng: newLng }))
          }
          setShowMap={setShowMap}
          onClick={handleMapClick}
          needLocation={false}
        />
      )}
      <div className="fixed h-full overflow-hidden p-5 z-30 top-0 w-full left-0">
        <div className="flex relative items-center overflow-hidden justify-center h-full text-center sm:block">
          {loading && (
            <div className="absolute w-full h-full bg-gray-900 z-30 bg-opacity-40 flex justify-center">
              <Spinner
                children={"Chargement..."}
                className="h-10 w-10 text-main self-center"
              />
            </div>
          )}
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-900 opacity-75" />
          </div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
            &#8203;
          </span>

          <div
            className="inline-block align-center  overflow-y-auto max-h-[calc(100%)] bg-white rounded-3xl text-left overflow-hidden shadow-xl transform transition-all sm:align-middle max-w-4xl w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="bg-white pt-5 pb-4 px-4 sm:pb-4">
              <div className="flex justify-between items-center mb-4">
                <h2 className="font-bold lg:text-xl md:text-lg text-base text-orange-500">
                  {data && !isConsulting
                    ? "Modification d'un monument"
                    : data && isConsulting
                    ? "Consultation d'un monument"
                    : "Ajout d'un monument"}
                </h2>
                <div
                  className="text-gray-400 bg-gray-100 p-2 rounded-xl cursor-pointer"
                  onClick={() => {
                    setSelectedMonument(undefined);
                    setShowFormular(false);
                  }}
                >
                  <RiCloseFill className="w-6 h-6" />
                </div>
              </div>

              {!isConsulting && (
                <div className="mb-4 flex justify-center gap-1 items-center">
                  {steps.map((step, index) => (
                    <button
                      key={step}
                      type="button"
                      onClick={() => setCurrentStep(index)}
                      className={`w-1/2 py-1 rounded-2xl ${
                        currentStep === index
                          ? "bg-orange-500 text-white"
                          : "bg-gray-200 text-gray-700"
                      }`}
                    >
                      {step}
                    </button>
                  ))}
                </div>
              )}

              {renderStep()}
            </div>
            <div className="w-full">
              <Progress
                className="h-2"
                color="amber"
                value={progress}
                variant="filled"
              />
            </div>
            {!isConsulting && (
              <div className="bg-gray-50 px-4 py-3 text-center">
                <button
                  type="button"
                  onClick={handlePrevious}
                  disabled={currentStep === 0}
                  className="py-2 px-4 bg-gray-500 text-white disabled:bg-gray-200 font-semibold text-sm rounded-xl hover:bg-gray-700 mr-2"
                >
                  Précédent
                </button>
                {currentStep < steps.length - 1 ? (
                  <div
                    type="button"
                    onClick={handleNext}
                    className="py-2 px-4 bg-orange-400 inline cursor-pointer text-white font-semibold text-sm rounded-xl hover:bg-orange-500"
                  >
                    Suivant
                  </div>
                ) : (
                  <button
                    color="success"
                    disabled={loading}
                    className="py-2 px-4 bg-orange-400 text-white font-semibold text-sm rounded-xl hover:bg-orange-500"
                    type="submit"
                  >
                    {loading ? (
                      <Spinner size="sm" />
                    ) : data ? (
                      "Mettre à jour"
                    ) : (
                      "Créer"
                    )}
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </form>
  );
}
