import React, { useState, useEffect, useRef, useContext } from "react";
import City from "../models/City";
import ReactSelect from "react-select";
import * as Yup from "yup";
import { useFormik } from "formik";
import { updateFile, uploadFile } from "../api/fileService";
import AlertContext from "../context/AlertContext/allertContext";
import { Progress } from "@material-tailwind/react";
import { fetchCities } from "../api/citiesService";
import { toogleOverflow } from "../utils/helpers/style_helpers";
import { Spinner } from "reactstrap";
import { RiPencilLine } from "react-icons/ri";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import MapComponent from "./Map";
import CityDropdown from "./Cities/CityDropdown";

const CityFormular = ({ setShowFormular, data, setSelectedCity, setdata }) => {
  const { showAlert } = useContext(AlertContext);
  const error = "";
  const imageRef = useRef(null);
  const iconRef = useRef(null);
  const [file, setFile] = useState(null);
  const [icon, setIcon] = useState(null);
  const [cityPreview, setCityPreview] = useState("");
  const [iconPreview, setIconPreview] = useState("");
  const [loading, setLoading] = useState(false);
  const [showFileRequiredError, setShowFileRequiredError] = useState(false);
  const [showIconRequiredError, setShowIconRequiredError] = useState(false);
  const [progress, setProgress] = useState(0);
  const [disponibleCities, setDisponibleCities] = useState([]);
  const [showMap, setShowMap] = useState(false);
  const [coordinates, setCoordinates] = useState(
    data?.coordinates || { lat: 48.864716, lng: 2.349014 }
  );
  const [location, setLocation] = useState("");
  const [city, setCity] = useState();

  useEffect(() => {
    validation.setFieldValue("name", city);
  }, [city]);
  useEffect(() => {
    if (data?.image) {
      fetchImageFromFirebase(data.image, false);
      setFile(data.image);
    }
    if (data?.iconUrl) {
      fetchImageFromFirebase(data.iconUrl, true);
      setIcon(data.iconUrl);
    }
    if (data?.city) {
      validation.setFieldValue("name", data?.city);
    }
    const unsubscribe = City.getAllCities(callBack);

    toogleOverflow();
    return () => {
      unsubscribe();
      toogleOverflow();
    };
  }, []);

  const callBack = async (allData) => {
    var cities = await fetchCities();
    cities = cities ?? [];
    cities = cities?.filter((city) => {
      return !allData.some(
        (d) => d.name.toLowerCase() === city.value.toLowerCase()
      );
    });

    if (data && data.name) {
      cities?.unshift({
        value: data.name,
        label: data.name,
      });
    }
    setDisponibleCities(cities);
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: data ? data.name : "",
      // longtitude: data ? (data.coordinates ? data.coordinates[1] : 0) : 0,
      // latitude: data ? (data.coordinates ? data.coordinates[0] : 0) : 0,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Veuillez entrer le nom de votre ville."),
      // longtitude: Yup.number()
      //   .max(180)
      //   .min(-180)
      //   .required("Veuillez entrer la longitude de votre ville."),
      // latitude: Yup.number()
      //   .max(90)
      //   .min(-90)
      //   .required("Veuillez entrer la latitude de votre ville."),
    }),
    onSubmit: async (values) => {
      if (!file) {
        setShowFileRequiredError(true);
        return;
      }

      setShowFileRequiredError(false);

      if (loading) return;
      setLoading(true);

      try {
        let cityData = {
          name: data?.city || city,
          image: data?.image || "",
          iconUrl: data?.iconUrl || "",
          coordinates: coordinates,
          address: location,
        };

        if (file && (!data?.id || file !== data?.image)) {
          try {
            let imageUrl;
            // UPDATE FILES
            if (data?.id) {
              imageUrl = await updateFile(
                file,
                data.image,
                "cities",
                (progressEvent) => {
                  const progressPercentage = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  setProgress(progressPercentage);
                }
              );
            } else {
              // UPLOAD FILES
              imageUrl = await uploadFile(file, "cities", (progressEvent) => {
                const progressPercentage = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                setProgress(progressPercentage);
              });
            }
            if (imageUrl) {
              cityData.image = imageUrl;
            }
          } catch (e) {
            showAlert(
              `Error ${data?.id ? "updating" : "uploading"} city image`,
              "error"
            );
            setLoading(false);
            return;
          }
        }

        if (icon && (!data?.id || icon !== data?.iconUrl)) {
          try {
            let iconUrl;
            // UPDATE FILES
            if (data?.id) {
              iconUrl = await updateFile(
                icon,
                data.iconUrl,
                "markers",
                (progressEvent) => {
                  const progressPercentage = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  setProgress(progressPercentage);
                }
              );
            } else {
              // UPLOAD FILES
              iconUrl = await uploadFile(icon, "markers", (progressEvent) => {
                const progressPercentage = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                setProgress(progressPercentage);
              });
            }
            if (iconUrl) {
              cityData.iconUrl = iconUrl;
            }
          } catch (e) {
            showAlert(
              `Error ${data?.id ? "updating" : "uploading"} city Icon`,
              "error"
            );
            setLoading(false);
            return;
          }
        }

        if (!data?.id) {
          // CREATE A NEW CITY
          await City.create(
            cityData.name,
            cityData.image,
            cityData.iconUrl,
            cityData.coordinates,
            cityData.address
          );
          showAlert("La ville a été créée avec succès.");
        } else {
          // UPDATE A  CITY
          const city = new City({ ...data, ...cityData });
          await city.save();
          setSelectedCity(undefined);
          showAlert("La ville a été mise à jour avec succès.");
        }

        setLoading(false);
        setShowFormular(false);
      } catch (error) {
        console.error("Error in city form submission:", error);
        showAlert("Une erreur s'est produite. Veuillez réessayer.", "error");
        setLoading(false);
      }
    },
  });
  const handleThumbnailChange = (event) => {
    if (event.target.files.length > 0) {
      const selectedFile = event.target.files[0];
      setFile(selectedFile);

      // Create a local URL for the image file
      const previewUrl = URL.createObjectURL(selectedFile);
      setCityPreview(previewUrl);

      // Assuming validation is a formik or similar object
      validation.setFieldValue("image", selectedFile.name);
    } else {
      setFile(null);
      setCityPreview(null); // Clear the preview if no file is selected
      validation.setFieldValue("image", "");
    }
  };

  const handleIconChange = (event) => {
    if (event.target.files.length > 0) {
      const selectedFile = event.target.files[0];
      setIcon(selectedFile);

      // Create a local URL for the image file
      const previewUrl = URL.createObjectURL(selectedFile);
      setIconPreview(previewUrl);

      // Assuming validation is a formik or similar object
      validation.setFieldValue("icon", selectedFile.name);
    } else {
      setIcon(null);
      setIconPreview(null); // Clear the preview if no file is selected
      validation.setFieldValue("icon", "");
    }
  };

  const fetchImageFromFirebase = async (imageUrl, isIcon = false) => {
    try {
      const storage = getStorage();
      const imageRef = ref(storage, imageUrl);
      const url = await getDownloadURL(imageRef);
      if (isIcon) {
        validation.setFieldValue("icon", url);
        setIconPreview(url);
      } else {
        validation.setFieldValue("image", url);
        setCityPreview(url);
      }
    } catch (error) {
      console.error("Error fetching image from Firebase:", error);
      if (error.code === "storage/unauthorized") {
        showAlert(
          "Permission denied. Please check your Firebase permissions.",
          "error"
        );
      } else {
        showAlert(`Error loading image: ${error.message}`, "error");
      }
    }
  };

  const handleMapClick = (data) => {
    setCoordinates({
      lng: data.latLng.lng(),
      lat: data.latLng.lat(),
    });
  };

  const handleCitySelect = (city) => {
    setSelectedCity(city);
    setdata({ ...data, name: city });
    validation.setFieldValue("name", city);
  };
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
      action="#"
    >
      {showMap && (
        <MapComponent
          lat={coordinates.lat}
          lng={coordinates.lng} // Make sure it's lng here
          setLat={(newLat) =>
            setCoordinates((prev) => ({ ...prev, lat: newLat }))
          }
          setLng={(newLng) =>
            setCoordinates((prev) => ({ ...prev, lng: newLng }))
          }
          setLocation={(location) => setLocation(location)}
          setShowMap={setShowMap}
          onClick={handleMapClick}
          setCity={(city) => setCity(city)}
          needLocation={true}
        />
      )}
      <div className="fixed h-full overflow-hidden p-5 z-30 top-0 w-full left-0">
        <div className="flex relative items-center overflow-hidden justify-center  h-full  text-center sm:block  ">
          {loading && (
            <div className="absolute w-full  h-full bg-gray-900 z-30 bg-opacity-40 flex justify-center">
              <Spinner
                children={"Chargement..."}
                className="h-10 w-10 text-main self-center"
              />
            </div>
          )}
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-900 opacity-75" />
          </div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
            &#8203;
          </span>
          <div
            className="inline-block relative align-center bg-white rounded-3xl text-left  
          shadow-xl transform transition-all overflow-y-auto max-h-[calc(100%-4rem)]     sm:align-middle sm:max-w-lg w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="flex justify-between items-center mb-4">
                <h2 className="font-bold lg:text-xl md:text-lg text-base text-orange-500">
                  {!!data?.id
                    ? "Modification d'une ville"
                    : "Ajout d'une ville"}
                </h2>
                <div
                  className="text-gray-400 bg-gray-100 p-2 rounded-full cursor-pointer"
                  onClick={() => {
                    setdata(undefined);
                    setShowFormular(false);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    className="w-7 h-7"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>
              </div>

              <div className="flex lg:flex-row flex-col mb-3 gap-3">
                <div className="flex-1">
                  <label htmlFor="name" className="font-bold text-base">
                    Ville <span className="text-red-500">*</span>
                  </label>

                  <div>
                    <div className="flex lg:flex-row flex-col mb-3 my-1.5">
                      <div className="flex-1 lg:mr-2 lg:mb-0 mb-3">
                        <label
                          htmlFor="longtitude"
                          className="text-gray-400 block mb-2 font-semibold text-sm"
                        >
                          Latitude <span className="text-red-500">*</span>
                        </label>
                        <input
                          className="w-full  border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4 rounded-full"
                          placeholder="Latitude"
                          type="number"
                          value={coordinates.lat}
                          onChange={(e) =>
                            setCoordinates({
                              ...coordinates,
                              lat: parseFloat(e.target.value),
                            })
                          }
                        />
                        {validation.touched.latitude &&
                        validation.errors.latitude ? (
                          <p className="text-red-500 text-xs">
                            {validation.errors.latitude}
                          </p>
                        ) : null}
                      </div>

                      <div className="flex-1 lg:ml-2 lg:mb-0 mb-3">
                        <label
                          htmlFor="longtitude"
                          className="text-gray-400 block mb-2  font-semibold text-sm"
                        >
                          Longitude <span className="text-red-500">*</span>
                        </label>
                        <input
                          className="w-full  border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4 rounded-full"
                          placeholder="Longitude"
                          type="number"
                          value={coordinates.lng}
                          onChange={(e) =>
                            setCoordinates({
                              ...coordinates,
                              lng: parseFloat(e.target.value),
                            })
                          }
                        />
                        {validation.touched.longtitude &&
                        validation.errors.longtitude ? (
                          <p className="text-red-500 text-xs">
                            {validation.errors.longtitude}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <h1
                      className="mt-3 bg-gray-100 p-2 text-center hover:bg-gray-200 rounded-xl w-full cursor-pointer"
                      onClick={() => setShowMap(true)}
                    >
                      selectionner la position
                    </h1>
                  </div>
                </div>
              </div>
              <input
                type="text"
                value={location || data?.name}
                readOnly
                placeholder="Recherchez une ville"
                className={`w-full border-2 text-gray-500 capitalize
  outline-none border-gray-200 bg-gray-50 py-2 mb-3 pl-4 rounded-full appearance-none`}
              />

              <div className="w-full mb-3">
                <label
                  htmlFor="address"
                  className="text-gray-800 block mb-2 font-bold"
                >
                  Image <span className="text-red-500">*</span>
                </label>
                <div className="relative">
                  {(data?.image || cityPreview) && (
                    <label
                      htmlFor="thumbnail"
                      className="cursor-pointer absolute bg-orange-500 rounded-full p-1.5 -right-2 -top-2 "
                    >
                      <RiPencilLine className="text-white" size={20} />
                    </label>
                  )}
                  <input
                    ref={imageRef}
                    type="file"
                    name="thumbnail"
                    id="thumbnail"
                    accept="image/*"
                    onChange={handleThumbnailChange}
                    className={`w-full border-2 text-gray-500 ${
                      data?.image || cityPreview ? "hidden" : "inline"
                    } outline-none border-gray-200 bg-gray-50 py-2 pl-4 rounded-full appearance-none`}
                  />
                  {cityPreview && (
                    <img
                      src={cityPreview}
                      alt="Thumbnail Preview"
                      className=" w-full h-full object-cover"
                    />
                  )}
                </div>
                {showFileRequiredError ? (
                  <h1 className="text-red-500 font-semibold">
                    Aucune image sélectionnée. Veuillez choisir une image avant
                    de continuer.
                  </h1>
                ) : null}
              </div>

              <div className="w-full mb-3">
                <label
                  htmlFor="address"
                  className="text-gray-800 block mb-2 font-bold"
                >
                  Icône <span className="text-red-500">*</span>
                </label>
                <div className="relative">
                  {(data?.iconUrl || iconPreview) && (
                    <label
                      htmlFor="icon"
                      className="cursor-pointer absolute bg-orange-500 rounded-full p-1.5 -right-2 -top-2 "
                    >
                      <RiPencilLine className="text-white" size={20} />
                    </label>
                  )}
                  <input
                    ref={iconRef}
                    type="file"
                    name="icon"
                    id="icon"
                    accept="image/*"
                    onChange={handleIconChange}
                    className={`w-full border-2 text-gray-500 ${
                      data?.iconUrl || iconPreview ? "hidden" : "inline"
                    } outline-none border-gray-200 bg-gray-50 py-2 pl-4 rounded-full appearance-none`}
                  />
                  {iconPreview && (
                    <div className="flex w-full justify-center">
                      <img
                        src={iconPreview}
                        alt="Icon Preview"
                        className=" w-24 h-full object-cover"
                      />
                    </div>
                  )}
                </div>
                {showIconRequiredError ? (
                  <h1 className="text-red-500 font-semibold">
                    Aucune Icône sélectionnée. Veuillez choisir une image avant
                    de continuer.
                  </h1>
                ) : null}
              </div>
            </div>
            <div className="w-full">
              <Progress
                className="h-2"
                color="amber"
                value={progress}
                variant="filled"
              />
            </div>
            <div className="bg-gray-50 px-4 py-3 text-center">
              <button
                onClick={() => {
                  setdata(undefined);
                  setShowFormular(false);
                }}
                type="button"
                className="md:py-3 py-2 md:px-8 px-4 bg-gray-500 text-white font-semibold lg:text-lg text-sm rounded-xl hover:bg-gray-700 mr-2"
              >
                Annuler
              </button>
              <button
                disabled={error ? null : loading ? true : false}
                className="md:py-3 py-2 md:px-10 px-5 bg-amber-400 text-white font-semibold lg:text-lg text-sm rounded-xl hover:bg-amber-500"
                type="submit"
              >
                {error ? null : loading ? (
                  <h1 className="text-sm me-2"> Loading... </h1>
                ) : // <ComponentLoader /> //loader added
                !!data?.id ? (
                  "Mettre à jour"
                ) : (
                  "Créer"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default CityFormular;
