import React, { useState, useEffect, useRef, useContext } from "react";
import City from "../models/City";
import Visit from "../models/Visit";
import * as Yup from "yup";
import { useFormik } from "formik";
import ReactSelect from "react-select";
import { Progress } from "@material-tailwind/react";
import { Spinner } from "reactstrap";
import AlertContext from "../context/AlertContext/allertContext";
import { toogleOverflow } from "../utils/helpers/style_helpers";
import {
  uploadFile,
  getFile,
  deleteFile,
  updateFile,
} from "../api/fileService";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { RiPencilLine } from "react-icons/ri";
import Coupon from "../models/Coupon";

const VisitForm = ({ setShowFormular, data, setSelectedVisit, setdata }) => {
  const { showAlert } = useContext(AlertContext);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(undefined);
  const [file, setFile] = useState(null);
  const [visitPreview, setVisitPreview] = useState("");
  const [showFileRequiredError, setShowFileRequiredError] = useState(false);
  const [showCityError, setShowCityError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [coupons, setCoupons] = useState([]);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const fileInputRef = useRef(null);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: data ? data.name : "",
      description: data ? data.description : "",
      distance: data ? data.distance : 0,
      duration: data ? data.duration : 0,
      price: data ? data.price : 0,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter visit name"),
      description: Yup.string().required("Please Enter visit description"),
      distance: Yup.number().positive().required("Please Enter visit distance"),
      price: Yup.number().positive().required("Please Enter visit price"),
      duration: Yup.number().positive().required("Please Enter visit duration"),
      // couponID: Yup.string().required("Please select a coupon"),
    }),
    onSubmit: async (values) => {
      if (loading) return;
      setLoading(true);

      if (!file && !data?.image) {
        setShowFileRequiredError(true);
        setLoading(false);
        return;
      }

      if (selectedCity === undefined) {
        setShowCityError(true);
        setLoading(false);
        return;
      }

      try {
        let imageUrl = data?.image || "";

        if (file) {
          if (data?.image) {
            imageUrl = await updateFile(
              file,
              data.image,
              "visits",
              (progressEvent) => {
                const progressPercentage = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                setProgress(progressPercentage);
              }
            );
          } else {
            imageUrl = await uploadFile(file, "visits", (progressEvent) => {
              const progressPercentage = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setProgress(progressPercentage);
            });
          }
        }

        const visitData = {
          name: values.name,
          image: imageUrl,
          distance: values.distance,
          duration: values.duration,
          description: values.description,
          price: values.price,
          cityID: selectedCity.value,
          couponID: selectedCoupon?.value,
        };

        if (data) {
          Object.assign(data, visitData);
          await data.save();
          setSelectedVisit(undefined);
          showAlert("La visite a été mise à jour avec succès.");
        } else {
          await Visit.create(visitData);
          showAlert("La visite a été créée avec succès.");
        }

        setShowFormular(false);
      } catch (error) {
        console.error("Error saving visit:", error);
        showAlert(
          "Une erreur est survenue lors de l'enregistrement de la visite.",
          "error"
        );
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    const loadCities = async () => {
      const querySnapshot = await City.getAllC();
      let myData = [];
      querySnapshot.forEach((doc) => {
        myData.push({ value: doc.id, label: doc.data().name });
      });
      setCities(myData);
      if (data !== undefined) {
        let d = myData.filter((e) => e.value === data.cityID);
        setSelectedCity(d[0]);
      }
    };
    loadCities();

    const loadCoupons = async () => {
      const querySnapshot = await Coupon.getAllCpns();
      let couponData = [];
      querySnapshot.forEach((doc) => {
        couponData.push({ value: doc.id, label: doc.data().code_name });
      });
      setCoupons(couponData);
      if (data !== undefined) {
        let d = couponData.filter((e) => e.value === data.couponID);
        setSelectedCoupon(d[0]);
      }
    };
    loadCoupons();

    if (data?.image) {
      fetchImageFromFirebase(data.image);
    }

    toogleOverflow();
    return () => {
      toogleOverflow();
    };
  }, [data]);

  const fetchImageFromFirebase = async (imageUrl) => {
    try {
      const storage = getStorage();
      const imageRef = ref(storage, imageUrl);
      const url = await getDownloadURL(imageRef);
      validation.setFieldValue("image", url);
      setVisitPreview(url);
    } catch (error) {
      console.error("Error fetching image from Firebase:", error);
      if (error.code === "storage/unauthorized") {
        showAlert(
          "Permission denied. Please check your Firebase permissions.",
          "error"
        );
      } else {
        showAlert(`Error loading image: ${error.message}`, "error");
      }
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setVisitPreview(URL.createObjectURL(selectedFile));
      setShowFileRequiredError(false);
    }
  };


  return (
    <form onSubmit={validation.handleSubmit}>
      <div className="fixed h-full overflow-hidden p-5 z-30 top-0 w-full left-0">
        <div className="flex relative items-center overflow-hidden justify-center h-full text-center sm:block">
          {loading && (
            <div className="absolute w-full h-full bg-gray-900 z-30 bg-opacity-40 flex justify-center">
              <Spinner className="h-10 w-10 text-main self-center">
                Chargement...
              </Spinner>
            </div>
          )}
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-900 opacity-75" />
          </div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
            &#8203;
          </span>
          <div className="inline-block align-center overflow-y-auto max-h-[calc(100%-4rem)] bg-white rounded-3xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="flex justify-between items-center mb-4">
                <h2 className="font-bold lg:text-xl md:text-lg text-base text-orange-500">
                  {!!data?.id
                    ? "Modification d'une visite"
                    : "Ajout d'une visite"}
                </h2>
                <div
                  className="text-gray-400 bg-gray-100 p-2 rounded-full cursor-pointer"
                  onClick={() => {
                    setdata(undefined);
                    setSelectedVisit(undefined);
                    setShowFormular(false);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    className="w-7 h-7"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>
              </div>

              <div className="mb-3">
                <label
                  htmlFor="name"
                  className="text-gray-800 block mb-2 font-bold"
                >
                  Nom <span className="text-red-500">*</span>
                </label>
                <input
                  name="name"
                  className="w-full border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4 rounded-full"
                  placeholder="Entrer le nom"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.name || ""}
                />
                {validation.touched.name && validation.errors.name && (
                  <p className="text-red-500 text-xs">
                    {validation.errors.name}
                  </p>
                )}
              </div>

              <div className="mb-3">
                <label className="block font-bold text-sm mb-3" htmlFor="city">
                  Ville <span className="text-red-500">*</span>
                </label>
                <ReactSelect
                  value={selectedCity}
                  options={cities}
                  onChange={(val) => setSelectedCity(val)}
                  menuShouldScrollIntoView={true}
                  maxMenuHeight={200}
                />
                {showCityError && (
                  <p className="text-red-500 text-xs">
                    Veuillez choisir une ville avant de continuer.
                  </p>
                )}
              </div>

              <div className="mb-3">
                <label
                  htmlFor="distance"
                  className="text-gray-800 block mb-2 font-bold"
                >
                  Distance <span className="text-red-500">*</span>
                </label>
                <div className="flex flex-row items-center">
                  <input
                    name="distance"
                    className="w-full border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4 rounded-full"
                    placeholder="Entrer la distance"
                    type="number"
                    min={0}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.distance}
                  />
                  <span className="px-3 font-bold text-orange-500">(km)</span>
                </div>
                {validation.touched.distance && validation.errors.distance && (
                  <p className="text-red-500 text-xs">
                    {validation.errors.distance}
                  </p>
                )}
              </div>

              <div className="mb-3 flex gap-3">
                <div className="w-full">
                  <label
                    htmlFor="price"
                    className="text-gray-800 block mb-2 font-bold"
                  >
                    Prix <span className="text-red-500">*</span>
                  </label>
                  <div className="flex flex-row items-center">
                    <input
                      name="price"
                      className="w-full border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4 rounded-full"
                      placeholder="Entrer le prix"
                      type="number"
                      min={0}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.price}
                    />
                    <span className="px-3 font-bold text-orange-500">(€)</span>
                  </div>
                  {validation.touched.price && validation.errors.price && (
                    <p className="text-red-500 text-xs">
                      {validation.errors.price}
                    </p>
                  )}
                </div>

                <div className="w-full">
                  <label
                    htmlFor="duration"
                    className="text-gray-800 block mb-2 font-bold"
                  >
                    Durée <span className="text-red-500">*</span>
                  </label>
                  <div className="flex flex-row items-center">
                    <input
                      name="duration"
                      className="w-full border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4 rounded-full"
                      placeholder="Entrer la durée"
                      type="number"
                      min={0}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.duration}
                    />
                    <span className="px-3 font-bold text-orange-500">
                      (min)
                    </span>
                  </div>
                  {validation.touched.duration &&
                    validation.errors.duration && (
                      <p className="text-red-500 text-xs">
                        {validation.errors.duration}
                      </p>
                    )}
                </div>
              </div>
              <div className="mb-3">
                <label
                  className="block font-bold text-sm mb-3"
                  htmlFor="coupon"
                >
                  Coupon
                </label>
                <ReactSelect
                  value={selectedCoupon}
                  options={coupons}
                  onChange={(val) => setSelectedCoupon(val)}
                  menuShouldScrollIntoView={true}
                  maxMenuHeight={200}
                />
                {/* {selectedCoupon && isCon && (
                  <div className="mb-3">
                    <label className="block font-bold text-sm mb-1">
                      Coupon: {selectedCoupon.label}
                    </label>
                    <p className="text-gray-500">
                      {console.log({ selectedCoupon })}
                      Discount: {selectedCoupon.discount_value} %
                    </p>
                  </div>
                )} */}
              </div>

              <div className="mb-3">
                <label
                  htmlFor="description"
                  className="text-gray-800 block mb-2 font-bold"
                >
                  Description <span className="text-red-500">*</span>
                </label>
                <textarea
                  name="description"
                  className="w-full border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4 rounded-md"
                  placeholder="Entrer la description"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.description || ""}
                />
                {validation.touched.description &&
                  validation.errors.description && (
                    <p className="text-red-500 text-xs">
                      {validation.errors.description}
                    </p>
                  )}
              </div>

              <div className="w-full mb-3">
                <label
                  htmlFor="image"
                  className="text-gray-800 block mb-2 font-bold"
                >
                  Image <span className="text-red-500">*</span>
                </label>
                <div className="relative">
                  {(data?.image || visitPreview) && (
                    <label
                      htmlFor="image"
                      className="cursor-pointer absolute bg-orange-500 rounded-full p-1.5 -right-2 -top-2 "
                    >
                      <RiPencilLine className="text-white" size={20} />
                    </label>
                  )}
                  <input
                    ref={fileInputRef}
                    type="file"
                    name="image"
                    id="image"
                    accept="image/*"
                    onChange={handleFileChange}
                    className={`w-full border-2 text-gray-500 ${
                      data?.image || visitPreview ? "hidden" : "inline"
                    } outline-none border-gray-200 bg-gray-50 py-2 pl-4 rounded-full appearance-none`}
                  />
                  {visitPreview && (
                    <img
                      src={visitPreview}
                      alt="Visit Preview"
                      className="mt-2 max-w-full h-auto"
                    />
                  )}
                </div>
                {showFileRequiredError && (
                  <p className="text-red-500 text-xs">
                    Veuillez choisir une image avant de continuer.
                  </p>
                )}
              </div>
            </div>

            <div className="w-full">
              <Progress
                className="h-2"
                color="amber"
                value={progress}
                variant="filled"
              />
            </div>

            <div className="bg-gray-50 px-4 py-3 text-center">
              <button
                onClick={() => {
                  setdata(undefined);
                  setSelectedVisit(undefined);
                  setShowFormular(false);
                }}
                type="button"
                className="py-3 px-8 bg-gray-500 text-white font-semibold lg:text-lg text-sm rounded-xl hover:bg-gray-700 mr-2"
              >
                Annuler
              </button>
              <button
                disabled={loading}
                className="py-3 px-10 bg-amber-400 text-white font-semibold lg:text-lg text-sm rounded-xl hover:bg-amber-500"
                type="submit"
              >
                {loading ? (
                  <span className="text-sm">Chargement...</span>
                ) : data !== undefined ? (
                  "Mettre à jour"
                ) : (
                  "Créer"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default VisitForm;
